import tinymce from 'tinymce/tinymce';

// A theme is also required
import 'tinymce/themes/silver';

// Any plugins you want to use has to be imported
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/template';
import 'tinymce/plugins/image';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/template';
import 'tinymce/plugins/code';

const plugins = [
    'paste',
    'link',
    'template',
    'image',
    'lists',
    'template',
    'table',
    'code'
];

const style_formats = [
    { title: 'Headings', items: [
        { title: 'Heading 1', format: 'h1' },
        { title: 'Heading 2', format: 'h2' },
        { title: 'Heading 3', format: 'h3' },
        { title: 'Heading 4', format: 'h4' },
        { title: 'Heading 5', format: 'h5' },
        { title: 'Heading 6', format: 'h6' }
    ]},
    { title: 'Blocks', items: [
        { title: 'Paragraph', format: 'p' },
        { title: 'Blockquote', format: 'blockquote' },
        { title: 'Div', format: 'div' },
        { title: 'Pre', format: 'pre' }
    ]},
    { title: 'Customs', items: [
        { title: 'Surtitre', block: 'div', classes: 'uptitle' }
    ]}
];

const
    template_dir = '/assets/admin/templates/',
    templates = [
    {
      title: "Texte à gauche et image à droite",
      url:  template_dir + "text-left_image-right.html",
      description: ""
    },
    {
        title: "Texte à droite et image à gauche",
        url:  template_dir + "text-right_image-left.html",
        description: ""
    },
    {
        title: "Trois images côte à côte",
        url:  template_dir + "three-image.html",
        description: ""
    },
    {
        title: "Image pleine largeur",
        url:  template_dir + "image-fullwidth.html",
        description: ""
    },
    {
        title: "Texte et description centré",
        url:  template_dir + "text-description-centered.html",
        description: ""
    },
];

const link_class_list = [
    {title: 'Primaire', value: 'btn -primary'},
    {title: 'Secondaire', value: 'btn -secondary'},
    {title: 'Lien', value: 'link'}
];


let editor_config = {
    selector: 'textarea',
    path_absolute : "/",
    content_css: '/assets/css/typography.css',
    height: 600,
    plugins: plugins,
    toolbar: 'styleselect | bold italic underline | link image | alignleft aligncenter alignright | bullist numlist | template | code',
    style_formats: style_formats,
    link_class_list: link_class_list,
    content_css: '/assets/front/css/typography.css',
    extended_valid_elements : 'input[id|name|value|type|class|style|required|placeholder|autocomplete|onclick]',
    relative_urls: false,
    file_picker_callback: function(callback, value, meta) {
        const x = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
        const y = window.innerHeight|| document.documentElement.clientHeight|| document.getElementsByTagName('body')[0].clientHeight;

        let type = 'image' === meta.filetype ? 'Images' : 'Files',
            url  = editor_config.path_absolute + 'laravel-filemanager?editor=tinymce5&type=' + type;

        tinyMCE.activeEditor.windowManager.openUrl({
            url : url,
            title : 'Filemanager',
            onMessage: (api, message) => {
                callback(message.content);
            }
        });
    },
    templates: templates,
}


// Initialize the app
tinymce.init(editor_config);

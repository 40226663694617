(function() {
  document.addEventListener('DOMContentLoaded', () => {
    const checkall = document.querySelector('.checkall');
    const checkboxs = document.querySelectorAll('.action-checkbox');

    checkall.addEventListener('click', () => {
      for (const checkbox of checkboxs) {
        checkbox.checked = checkall.checked;
      }
    });
  });
})();

(function() {
  document.addEventListener('DOMContentLoaded', () => {
    const actionForm = document.getElementById('action-form');
    const idsInputHidden = document.querySelector('#action-form input[name="ids"]');
    const actionDelete = document.querySelector('.mass-action-list .action[data-action="delete"]');

    actionDelete.addEventListener('click', handleActionDelete);

    function handleActionDelete(evt) {
      evt.preventDefault();

      const elementsToDelete = document.querySelectorAll('.action-checkbox[data-action="delete"]:checked');
      const values = [];

      for (const elem of elementsToDelete) {
        values.push(elem.value);
      }

      if (!values.length) {
        return;
      }

      const confirm = window.confirm('Êtes vous sur de vouloir supprimer ?');

      if (confirm) {
        idsInputHidden.value = JSON.stringify(values);
      }

      actionForm.submit();
    }
  });
})();

import '../../../scss/components/uploader.scss';

class Uploader {

  constructor(node)
  {
    this.node = node;
    this.multiple = this.node.getAttribute('data-multiple');
    this.name = this.node.getAttribute('data-name');
    this.elements = [...this.node.querySelectorAll('label')];
    this.mediaToDelete = [];
    this.init();
  }

  /**
   * Init inputs fields and setup events
   */
  init()
  {
    for (const elem of this.elements) {
      this.setupEvent(elem);
    }
  }

  /**
   * Setup events on input
   * @param {node} elem
   */
  setupEvent(elem)
  {
    elem.addEventListener('change', () => {
      this.readUrl(elem);
      this.generateRemove(elem);
      this.multiple ? this.generateUpload() : null;
    });

    elem.addEventListener('click', (event) => {
      if (event.target.classList.contains('remove')) {
        event.preventDefault();
        this.handleRemove(elem);
        this.updateMediaToDelete();
      }
    });
  }

  /**
   * Generate button to remove element
   * @param {node} elem
   */
  generateRemove(elem)
  {
    const span = document.createElement('span');
    span.className = 'remove uil uil-times';
    elem.appendChild(span);
  }

  /**
   * Handle remove on current node
   * @param {node} elem
   */
  handleRemove(elem)
  {
    const mediaToDeleteId = elem.getAttribute('data-id');
    if (mediaToDeleteId) {
      this.mediaToDelete.push(mediaToDeleteId);
    }
    elem.parentNode.removeChild(elem);
    if (!this.multiple) {
      this.generateUpload();
    }
  }

  /**
   * Delete media to delete inputs and replace them by new ones
   */
  updateMediaToDelete()
  {
    console.log(this.mediaToDelete);
    const mediaToDeleteInputs = this.node.querySelectorAll('.media-to-delete');
    if (mediaToDeleteInputs.length) {
      for (const mediaInput of mediaToDeleteInputs) {
        this.node.removeChild(mediaInput);
      }
    }
    for (const mediaId of this.mediaToDelete) {
      console.log(mediaId);
      const input = document.createElement('input');
      input.name = 'mediaToDelete[]';
      input.className = 'media-to-delete';
      input.type = 'hidden';
      input.value = mediaId;
      this.node.appendChild(input);
    }
  }

  /**
   * Generate new fiel upload field
   */
  generateUpload()
  {
    const label = document.createElement('label');
    const input = document.createElement('input');
    const background = document.createElement('div');
    const icon = document.createElement('span');

    label.className = 'label';
    icon.className = 'uil uil-upload';
    input.name = this.name;
    input.type = 'file';
    input.className = 'add'
    background.className = 'background';

    this.node.appendChild(label);
    label.appendChild(input);
    label.appendChild(background);
    label.appendChild(icon);

    this.elements.push(label);
    this.setupEvent(label);
  }

  /**
   * Read uploaded image url and setup background
   */
  readUrl(elem)
  {
    const input = elem.querySelector('input');
    const background = elem.querySelector('.background');

    if (input.files && input.files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        background.style.backgroundImage = 'url(' + e.target.result + ')';
        background.style.backgroundSize = 'cover';
      }

      reader.readAsDataURL(input.files[0]);
    }
  }
}

document.addEventListener('DOMContentLoaded', function() {
  for (const uploader of document.querySelectorAll('.uploader')) {
    new Uploader(uploader);
  }
});

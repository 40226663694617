import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

const alerts = document.getElementsByClassName('toastr');

for (const alert of alerts) {
    const type = alert.getAttribute('data-type');
    const message = alert.getAttribute('data-value');

    if (type && message) {
        toastr[type](message);
    }
    continue;
}

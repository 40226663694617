import '../../../scss/components/editable.scss';
import toastr from 'toastr';
const axios = require('axios').default;

/**
 * Make field editable
 * @param {node} node
 */
const Editable = class {

  constructor(node) {
    this.node = node;
    this.id = node.getAttribute('data-pk');
    this.url = node.getAttribute('data-url');

    // Register events
    this.node.addEventListener('click', event => this.clickHandler(event));
  }

  /**
   * Update front node record
   */
  updateNode() {
    const inputValue = this.input.value
    this.node.textContent = inputValue;
    this.node.style.display = 'block';
    this.node.parentNode.removeChild(this.input);
    this.updateRecord(inputValue);
  }

  /**
   * Axios request
   * Update translation record by id
   * @param {string} inputValue
   */
  async updateRecord(inputValue) {
    // Get the token
    const csrfToken = document.querySelector('input[name="_token"]').getAttribute('value');

    // Stat the Async request
    let res = await axios.post(this.url, {
      id: this.id,
      value: inputValue,
    }, {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json, text-plain, */*",
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-TOKEN": csrfToken
      }
    });

    this.createAlert(res.data.type, res.data.message);
  }

  /**
   * Handle click event on the node
   * @param {event} event
   */
  clickHandler(event) {
    this.node.style.display = 'none';
    this.input = this.createEditableInput();
  }

  /**
   * Handle keyboard events
   * @param {event} event
   */
  keyPressHandler(event) {
    switch (event.key) {
      case 'Enter':
        this.updateNode();
        break;
    }
  }

  /**
   * Create input and assign node content to it
   */
  createEditableInput() {
    // Initiate element
    let input = document.createElement('input');
    let content = this.node.innerHTML;

    // Add attribute
    input.classList.add('input-editable');
    input.setAttribute('value', content);

    // Set event
    input.addEventListener('keypress', event => this.keyPressHandler(event));

    // Insert into the dom
    this.node.parentNode.appendChild(input);

    return input;
  }

  /**
   * Show alert in the dom
   * @param {string} type
   * @param {string} message
   */
  createAlert(type, message) {
    toastr[type](message);
  }
}


document.addEventListener('DOMContentLoaded', () => {
  const editablesElement = document.getElementsByClassName('editable');

  for (const element of editablesElement) {
    new Editable(element);
  }
})



